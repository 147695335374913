<script>
import PriceFormat from '@/mixins/priceFormat'
import DateFormat from '@/mixins/dateFormat'
import goTo from '@/mixins/goTo'
import BoutiqueProductCard from '@/components/presentation/boutiqueProduitCard'
import CommandeProductCard from '@/components/presentation/commandeProduitCard'
import CreditDisponible from '@/components/commande/creditDisponible'
import PageHeader from '@/components/shared/pageHeader'

export default {
  name: 'CommandesDetail',
  components: {
    BoutiqueProductCard,
    CreditDisponible,
    PageHeader,
    CommandeProductCard
  },
  mixins: [PriceFormat, DateFormat, goTo],
  data () {
    return {
      commande: null,
      loading: true,
      nonAuthorized: false
    }
  },
  apollo: {
    commande: {
      query: require('@/graphql/queries/v2/boutiqueCommande.gql'),
      client: 'boutique',
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      update (data) {
        this.loading = false
        return data.boutiqueCommande
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      error (e) {
        if (e.message.includes(403)) {
          this.nonAuthorized = true
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    goToBoutique () {
      this.goToBoutiqueCommande(this.$route.params.id)
    }
  },
  computed: {
    chips () {
      return [
        { loading: !this.commande, label: this.$t('commandes.achat-date', { date: this.dateFormat(this.commande?.createdAt) }) },
        { loading: !this.commande, label: this.priceFormat(this.commande?.total) }
      ]
    }
  }
}
</script>

<template lang="pug">
.commandes-detail
  page-header(
    :title="commande && commande.titre"
    :backButtonLabel="$t('menu.mes-commandes')"
    :backButtonTo="{ name: 'commandes___' + $i18n.locale, params: { lang: $i18n.locale } }"
    :chips="chips"
  )
  .page-actions
     v-card(color='primary', v-ripple, :disabled='loading || nonAuthorized', @click='goToBoutique')
        v-card-title {{ $t('commandes.voir-facture') }}
  section.large-section
    v-sheet.section-card(:color='$store.state.Preferences.darkMode ? "light" : "white"')

      //- Loading skeleton
      template(v-if='loading')
        .container(v-for='prod in 2', :key='prod')
          v-skeleton-loader(type='image')

      //- Not authorized
      template(v-else-if='nonAuthorized')
        .error-message
          v-alert(type='error') {{ $t('alerte.droits-manquants')}}

      //- Display list of commands
      template(v-else-if="commande")
        .commande-product-wrapper(v-for='(produit, index) in commande.commandesItems')
          commande-product-card(:produit="produit" :nuagePaquets="commande.nuagePaquets")

</template>
<style lang='sass' scoped>

.commande-product-wrapper
  position: relative
  display: flex
  flex-direction: column
  &:not(:last-child):after
    content: ''
    position: absolute
    width: 96%
    height: 1px
    left: 2%
    bottom: 0
    background-color: #ccc

.container
  display: grid
  grid-template-columns: 35% 15% 45%
  grid-template-rows: 1fr
  gap: 11px 0px
  grid-auto-flow: row
  grid-template-areas: ". . ."
  .v-alert__wrapper
    i
      margin: auto
  .custom-titre
    margin: auto 0
    margin-left: 1rem
  .credit-disp
    flex-direction: column
  .border-grid
    border-right: 1px solid #DEDEDE
    box-shadow: $shadow
    display: flex
    align-items: center
    justify-content: center
  .attente-paiement
    text-align: center
    font-size: 12px
    margin: auto
@media all and(max-width: $small)
  .container
    display: flex
    flex-wrap: wrap
    flex-direction: row
    grid-gap: unset
    gap: unset
    .credit-disp
      flex-direction: column
    .border-grid
      border-right: 1px solid #DEDEDE
      box-shadow: $shadow
      display: flex
      align-items: center
      justify-content: center
      width: 100%
</style>
