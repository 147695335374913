var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.creditProduits.length > 0)?_c('div',{staticClass:"credit-disponible"},_vm._l((_vm.creditProduits),function(role,index){return _c('div',{key:index},[_c('div',{staticClass:"role-credit"},[_c('div'),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"credit-restant"},[(index === 0)?_c('div',{staticClass:"header"},[_c('v-tooltip',{staticStyle:{"line-height":"1.15"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"icon":['fad', 'coins']}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('produit.credit-disponible', role.compteur ? role.compteur : 2)))])])],1):_vm._e()]),_c('div',{staticClass:"credit-utilise"},[(index === 0)?_c('div',{staticClass:"header"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"icon":['fad', 'users-class']}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('produit.credit-utilise', role.nuageItemsCount)))])])],1):_vm._e()])])]),_c('div',{staticClass:"role-credit"},[_c('div',{staticClass:"role"},[_c('div',{staticClass:"f5"},[_vm._v(_vm._s(_vm.tradRole(role.role)))])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"credit-restant"},[(!role.compteur)?_c('div',{staticClass:"ma2"},[_c('v-tooltip',{staticStyle:{"line-height":"1.15"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticStyle:{"font-size":"12px","color":"black"},attrs:{"icon":['fas', 'infinity']}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('produit.sans-limite')))])])],1):_c('div',{staticClass:"ma2"},[_vm._v(_vm._s(role.compteur))])]),_c('div',{staticClass:"credit-utilise"},[_c('div',{staticClass:"ma2 b"},[_vm._v(_vm._s(role.nuageItemsCount))])])])])])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }