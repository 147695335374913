<script>
import priceFormat from '@/mixins/priceFormat'
import manipulateProduct from '@/mixins/manipulateProduct'
import caracteristicTag from '@/components/products/caracteristicTag'

export default {
  name: 'ProductCard',
  components: {
    caracteristicTag
  },
  mixins: [priceFormat, manipulateProduct],
  props: {
    loading: { required: false, type: Boolean, default: false },
    hardcodded: { required: false, type: Boolean, default: false },
    produit: { required: false, type: Object, default: () => {} },
    listView: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    combo () {
      let temp = false
      if (this.produit && this.produit.items) {
        temp = !!this.produit.items.find(e => e.combo)
      }
      return temp
    },
    parentCard () {
      if (this.produit && this.produit.produit) {
        return this.produit.produit
      } else {
        return null
      }
    },
    activeProduit () {
      if (this.produit && this.produit.item) {
        return this.produit.item
      } else {
        return this.produit
      }
    },
    logo () {
      let img = null
      if (this.activeProduit && this.activeProduit.images && this.activeProduit.images.length > 0) {
        img = this.activeProduit.images.find((e) => {
          return e.tagsVisibles.find(t => t && t.id.includes('details-graphiques_image-pictogramme'))
        })
      }
      return img
    },
    couverture () {
      return this.getProduitCouverture(this.activeProduit)
    }
  },
  methods: {
  }
}
</script>
<template lang="pug">
.product-card(:class="{'list-view': listView }")
    //- nuxt-link.no-underline.h-100(:to=" parentCard ? { name: 'produits-slug-child___' + $i18n.locale, params: { child: activeProduit.slug, slug: parentCard.slug } } : { name: 'produits-slug___' + $i18n.locale, params: { slug: activeProduit.slug } }")
    div.product-image__wrapper.w-100(v-if='couverture')
      .background-color(v-if='couverture.couleursDominantes && couverture.couleursDominantes[0]', :style='{backgroundColor: couverture.couleursDominantes[0]}')
      v-img.h-100.relative(:src='couverture.medium_image', :lazy-src='couverture.thumbnail', :alt='couverture.description', :title='couverture.titre', contain)
    div.product-image__wrapper.w-100(v-else)
      .background-color.primary
      v-img.relative.o-50(v-if='logo', width='100px', height='100px', :src='logo.medium_image', :lazy-src='logo.thumbnail', :alt='logo.description', :title='logo.titre', contain)
      //- img.relative.o-50(v-else, width='100px', height='100px', src='@/assets/images/logos/icon_dokoma.svg', alt='logo-dokoma', contain)
    div.product-border(:style='{backgroundColor: (couverture && couverture.couleursDominantes && couverture.couleursDominantes[0]) ? couverture.couleursDominantes[0] : "red"}')
    .product-content__wrapper
      div.h-100.w-100(style='display:flex; flex-direction: column;')
        div.combo-info(v-if='combo')
          span {{ $t('action.combo') }}
        //- div.promotion-info(v-if='activeProduit.enPromotion') => PAS EN LIGNE POUR LINSTANT
          span {{ $t('action.en-promotion') }}
        h5 {{activeProduit.titre}}

        .product-order-info.mt2
          div.licence.b Licence: &nbsp;
            span.normal {{produit.tarifZone.titre}}
          caracteristic-tag(v-if='activeProduit.formats && activeProduit.formats.length > 0', :data='activeProduit.formats', icon='book', type='formats')
          div.duree.b.pl2(v-if='produit.tarifZone.finAt') Valable jusqu'au {{ new Date(produit.tarifZone.finAt).toLocaleDateString( $i18n.locale + '-CA', { day: 'numeric', month: 'short', year: 'numeric' })}}
        div.flex-grow-1
</template>
<style lang='sass'>
  @import '@/assets/style/components/presentation/_product-card'
  .product-card
    flex-direction: column
  .product-order-info
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: 1fr
    grid-auto-flow: row
    grid-template-areas: ". . ."
    font-size: 12px
  .combo-info
    position: absolute
    right: -5px
    top: -5px
    z-index: 1
    overflow: hidden
    width: 75px
    height: 75px
    text-align: right
    span
      font-size: 10px
      font-weight: bold
      color: #FFF
      text-transform: uppercase
      text-align: center
      line-height: 20px
      transform: rotate(45deg)
      -webkit-transform: rotate(45deg)
      width: 100px
      display: block
      background: #79A70A
      background: linear-gradient(var(--v-primary-base) 0%, var(--v-primary-base) 100%)
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1)
      position: absolute
      top: 19px
      right: -21px
    span::before
      content: ""
      position: absolute
      left: 0px
      top: 100%
      z-index: -1
      border-left: 3px solid var(--v-primary-base)
      border-right: 3px solid transparent
      border-bottom: 3px solid transparent
      border-top: 3px solid var(--v-primary-base)
    span::after
      content: ""
      position: absolute
      right: 0px
      top: 100%
      z-index: -1
      border-left: 3px solid transparent
      border-right: 3px solid var(--v-primary-base)
      border-bottom: 3px solid transparent
      border-top: 3px solid var(--v-primary-base)
  .promo-info
    position: absolute
    right: -5px
    top: -5px
    z-index: 1
    overflow: hidden
    width: 75px
    height: 75px
    text-align: right
    span
      font-size: 10px
      font-weight: bold
      color: #FFF
      text-transform: uppercase
      text-align: center
      line-height: 20px
      transform: rotate(45deg)
      -webkit-transform: rotate(45deg)
      width: 100px
      display: block
      background: #79A70A
      background: linear-gradient(var(--v-secondary-base) 0%, var(--v-secondary-base) 100%)
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1)
      position: absolute
      top: 19px
      right: -21px
    span::before
      content: ""
      position: absolute
      left: 0px
      top: 100%
      z-index: -1
      border-left: 3px solid var(--v-secondary-base)
      border-right: 3px solid transparent
      border-bottom: 3px solid transparent
      border-top: 3px solid var(--v-secondary-base)
    span::after
      content: ""
      position: absolute
      right: 0px
      top: 100%
      z-index: -1
      border-left: 3px solid transparent
      border-right: 3px solid var(--v-secondary-base)
      border-bottom: 3px solid transparent
      border-top: 3px solid var(--v-secondary-base)
</style>
