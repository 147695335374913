<script>
export default {
  name: 'CaracteristicTag',
  components: {
  },
  props: {
    loading: { required: false, type: Boolean, default: false },
    data: { required: true, type: Array, default: () => [] },
    icon: { required: true, type: String },
    type: { required: true, type: String }
  },
  data () {
    return {
      menu: false,
      hoverActive: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<template lang="pug">
  div.caracteristic(v-if='data', style='display: flex', @mouseover='hoverActive = true', @mouseleave='hoverActive = false', :style='{ "background-color": hoverActive && data[0].couleurBase ? data[0].couleurBase : (hoverActive ? "rgba(0, 0, 0, 0.05)" : "transparent") }')
    font-awesome-icon(:icon="['far', icon]", style='margin:0px 12px 0px 12px')
    div.caracteristic-title.black--text
      //- TODO FIX SSO ERROR
      //- client-only
      .black--text {{data[0].titre}}
      template(v-if='data.length > 1')
        v-menu(v-model='menu', :close-on-content-click='false', :nudge-width='200', offset-y)
          template(v-slot:activator='{ on, attrs }')
            v-btn.ml1.f3(v-bind='attrs', v-on='on', x-small, text, style='padding:0; font-weight: bold !important;', @click.stop.prevent='menu = !menu') (+{{data.length - 1}})
          v-card
            v-list(dense, style='padding: 0')
              v-subheader {{$t('produits.produits-formats')}}
              template(v-for='(item, index) in data')
                v-list-item.black--text
                  //- nuxt-link.no-underline.black--text(:to='{ name: "recherche___" + $i18n.locale, query: { [type]: item.id } }')
                  v-list-item-content
                    v-list-item-title {{item.titre}}
              template(v-for='(item, index) in data')
                v-list-item.black--text
                  //- nuxt-link.no-underline.black--text(:to='{ name: "recherche___" + $i18n.locale, query: { [type]: item.id } }')
                  v-list-item-content
                    v-list-item-title {{item.titre}}
</template>
<style lang='sass'>

</style>
