<script>
export default {
  name: 'PresentationCredits',
  props: {
    credits: { type: Object, default: null }
  },
  computed: {
    eleves () {
      return this.credits.eleves
    },
    enseignants () {
      return this.credits.enseignants
    }
  }
}
</script>

<template lang="pug">
.presentation-credits
  v-row.ma-0.pa-0
    v-col.pt-0(v-if="eleves" :cols="6")
      h1 {{ eleves.nuageItemsCount || 0 }}
      h4 {{ $t('commandes.credits-eleves-distrib') }}
    v-col.pt-0(v-if="eleves" :cols="6")
      h1 {{ eleves.compteur || 0 }}
      h4 {{ $t('commandes.credits-eleves-buy') }}
    v-col.pt-0(v-if="enseignants" :cols="6")
      h1 {{ enseignants.nuageItemsCount || 0 }}
      h4 {{ $t('commandes.credits-enseignant-distrib') }}
    v-col.pt-0(v-if="enseignants" :cols="6")
      h1 {{ enseignants.compteur || 0 }}
      h4 {{ $t('commandes.credits-enseignant-buy') }}
</template>

<style lang="sass" scoped>
.presentation-credits
  h1, h4
    text-align: center
    margin: 0
  h4
    font-size: 12px
    font-weight: 500

</style>
