<script>
export default {
  name: 'CreditDisponible',
  props: {
    produit: { required: true, type: Object },
    paquets: { required: true, type: Array }
  },
  data () {
    return {
    }
  },
  computed: {
    creditProduits () {
      let temp = []
      if (this.produit && this.paquets && this.paquets.length > 0) {
        temp = this.paquets.find(e => e.items.find(i => i.boutiqueItem.id === this.produit.item.id))
        if (temp.items && temp.items.length > 0) {
          temp = temp.items.find(i => i.boutiqueItem.id === this.produit.item.id)
        }
        if (temp.roles) {
          return temp.roles
        }
        return temp
      } else {
        return temp
      }
    }
  },
  methods: {
    tradRole (role) {
      let temp = role
      if (temp.includes('salle_classe_')) {
        temp = temp.replace('salle_classe_', '')
      }
      if (this.$i18n.locale === 'fr') {
        return temp
      } else if (this.$i18n.locale === 'en') {
        if (temp === 'enseignant') {
          return 'teacher'
        } else if (temp === 'eleve') {
          return 'student'
        } else {
          return temp
        }
      }
    }
  }
}
</script>

<template lang="pug">
  .credit-disponible(v-if='creditProduits.length > 0')
    div(v-for='(role, index) in creditProduits', :key='index')
      div.role-credit
        div
        div.flex
          div.credit-restant
            div.header(v-if='index === 0')
              v-tooltip(top, style='line-height:1.15;')
                template(v-slot:activator='{ on, attrs }')
                  font-awesome-icon(:icon="['fad', 'coins']", style='font-size: 12px', v-bind='attrs', v-on='on')
                span {{ $tc('produit.credit-disponible', role.compteur ? role.compteur : 2) }}
          div.credit-utilise
            div.header(v-if='index === 0')
              v-tooltip(top)
                template(v-slot:activator='{ on, attrs }', style='line-height:1.15;')
                  font-awesome-icon(:icon="['fad', 'users-class']", style='font-size: 12px', v-bind='attrs', v-on='on')
                span {{ $tc('produit.credit-utilise', role.nuageItemsCount) }}
      div.role-credit
        .role
          .f5 {{ tradRole(role.role) }}
        div.flex
          div.credit-restant
            div.ma2(v-if='!role.compteur')
              v-tooltip(top, style='line-height:1.15;')
                template(v-slot:activator='{ on, attrs }')
                  font-awesome-icon(:icon="['fas', 'infinity']", style='font-size: 12px;color: black', v-bind='attrs', v-on='on')
                span {{ $t('produit.sans-limite') }}
            div.ma2(v-else) {{role.compteur}}
          div.credit-utilise
            div.ma2.b {{role.nuageItemsCount}}
</template>

<style lang='sass'>
.credit-disponible
  width: 100%
.role-credit
  display: grid
  grid-template-columns: 60% 40%
  grid-template-rows: 1fr
  .role
    margin: auto
  .header
    text-align: center
  .credit-restant
    border-right: 1px solid #DEDEDE
    width: 50%
    text-align: center
    // background-color: var(--v-primary-base)
  .credit-utilise
    width: 50%
    text-align: center
    // background-color: grey
</style>
