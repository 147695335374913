<script>
import caracteristicTagSimplified from '@/components/products/caracteristicTagSimplified'
import presentationCredits from '@/components/products/presentationCredits'
import priceFormat from '@/mixins/priceFormat'
import manipulateProduct from '@/mixins/manipulateProduct'
import goTo from '@/mixins/goTo'

export default {
  name: 'CommandeProduitCard',
  mixins: [priceFormat, manipulateProduct, goTo],
  props: {
    produit: { type: Object, required: true },
    nuagePaquets: { type: Array, required: true }
  },
  components: { caracteristicTagSimplified, presentationCredits },
  computed: {
    couverture () {
      return this.getProduitCouverture(this.produit.item)
    },
    matieres () {
      return this.produit?.item?.matieres?.map(m => m.titre)?.join(', ')
    },
    niveaux () {
      return this.produit?.item?.niveaux?.map(m => m.titre)?.join(', ')
    },
    dateFinValidite () {
      const date = this.produit?.tarifZone?.finAt
      if (date) {
        return this.$moment(date).locale(this.$i18n.locale).subtract(1, 'days').format('DD MMMM YYYY')
      }
      return null
    },
    productInformations () {
      return [
        { name: 'niveaux', icon: ['far', 'graduation-cap'], text: this.niveaux },
        { name: 'matieres', icon: ['far', 'globe-stand'], text: this.matieres },
        { name: 'format', icon: ['far', 'book'], text: this.produit.item.formats[0].titre },
        { name: 'license', icon: ['far', 'file-certificate'], text: this.produit.tarifZone.titre },
        { name: 'validity', icon: null, text: this.$t('commandes.eligible-until') + this.dateFinValidite, bold: true, skip: !this.dateFinValidite }
      ]
    },
    creditsInfos () {
      return this.getCreditsInfosFromNuagePaquetAndItemId(this.nuagePaquets, this.produit?.item?.id)
    }
  }
}
</script>

<template lang="pug">
.commande-product-card.my-1.py-2
  v-row.w-100.mx-0

    //- Illustration
    v-col(:cols="4" :md="3" :lg="2")
      v-img.h-100.relative(v-if='couverture' :src='couverture.medium_image', :lazy-src='couverture.thumbnail', :alt='couverture.description', :title='couverture.titre', contain)

    //- Informations about product
    v-col(:cols="8" :md="5" :lg="6")
      h5.mb-2 {{ produit.item.titre }}
      caracteristic-tag-simplified(v-for="pi in productInformations" :key="pi.name" :infos="pi")

    //- Credits repartition
    v-col(:cols="12" :md="4")
      presentationCredits(:credits="creditsInfos")

  .product-card-actions
    .product-card-actions-buttons.d-flex
      v-btn.mr-2(color="primary" @click="goToBoutiqueItem(produit.produit.slug, produit.item.slug)") {{ $t('commandes.order-again')}}
      v-btn.mr-2(color="primary") {{ $t('commandes.distribuer-credits')}}
    h3 {{ priceFormat(produit.total) }}
</template>

<style lang="sass" scoped>
.v-image
  max-height: 150px
.product-card-actions
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding: 12px 36px 6px 36px
  .product-card-actions-buttons
    display: flex
  h3
    font-size: 26px
    color: var(--v-error-base)
  @media all and (max-width: $medium)
    gap: 18px
    flex-direction: column
</style>
